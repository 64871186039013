/**
 * User - Service
 * Der user-service aggregiert Benutzertyp übergreifend Benutzer (Innendienst, Außendienst, Mitbenutzer, Assistenten etc.) und gibt deren \"Basisstammdaten\" zurück. Der Service kann im Kontext eines angemeldeten Benutzers ausgeführt werden, so wie als technischer Service aufgerufen werden. Im Kontext eines angemeldeten Benutzers liest der Service nur die Benutzerdaten des angemeldeten Benutzers.
 *
 * The version of the OpenAPI document: v2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Address } from './address';
import { Club } from './club';
import { Strukturstufe } from './strukturstufe';
import { Direktionsbereich } from './direktionsbereich';
import { ContactInformation } from './contactInformation';


export interface User { 
    /**
     * Benutzerkennung
     */
    userId?: string;
    /**
     * AUSSENDIENST, INNENDIENST, MITBENUTZER, HOTLINE, AUTOTEST, KONZERN_MITARBEITER, ASSISTENT
     */
    userTyp?: User.UserTypEnum;
    /**
     * Anrede Herr/Frau/...
     */
    salutation?: string;
    /**
     * Akademischer Titel Prof./Dr./...
     */
    academicTitle?: string;
    firstName?: string;
    lastName?: string;
    birthday?: string;
    addresses?: Array<Address>;
    contactInformation?: Array<ContactInformation>;
    assistenten?: Array<string>;
    mitbenutzer?: Array<string>;
    vertreter?: Array<string>;
    /**
     * Url zu Avatarbild des Benutzers
     */
    pictureUrl?: string;
    vertriebsgesellschaftName?: string;
    direktion?: number;
    clubs?: Array<Club>;
    strukturstufe?: Strukturstufe;
    vertriebsgesellschaft?: User.VertriebsgesellschaftEnum;
    praxisstufe?: string;
    isDirektionsleiter?: boolean;
    isBDVMitglied?: boolean;
    isPremiumMitglied?: boolean;
    /**
     * Austrittsdatum
     */
    leavingDate?: string;
    direktionsgemeinschaft?: string;
    direktionsbereich?: Direktionsbereich;
}
export namespace User {
    export type UserTypEnum = 'AUSSENDIENST' | 'INNENDIENST' | 'MITBENUTZER' | 'HOTLINE' | 'AUTOTEST' | 'KONZERN_MITARBEITER' | 'SCHUELER' | 'ASSISTENT' | 'SERVICE_ACCOUNT';
    export const UserTypEnum = {
        Aussendienst: 'AUSSENDIENST' as UserTypEnum,
        Innendienst: 'INNENDIENST' as UserTypEnum,
        Mitbenutzer: 'MITBENUTZER' as UserTypEnum,
        Hotline: 'HOTLINE' as UserTypEnum,
        Autotest: 'AUTOTEST' as UserTypEnum,
        KonzernMitarbeiter: 'KONZERN_MITARBEITER' as UserTypEnum,
        Schueler: 'SCHUELER' as UserTypEnum,
        Assistent: 'ASSISTENT' as UserTypEnum,
        ServiceAccount: 'SERVICE_ACCOUNT' as UserTypEnum
    };
    export type VertriebsgesellschaftEnum = 'undefined' | 'DVAG' | 'DVAG_WIEN' | 'Allfinanz' | 'AllfinanzAG';
    export const VertriebsgesellschaftEnum = {
        Undefined: 'undefined' as VertriebsgesellschaftEnum,
        Dvag: 'DVAG' as VertriebsgesellschaftEnum,
        DvagWien: 'DVAG_WIEN' as VertriebsgesellschaftEnum,
        Allfinanz: 'Allfinanz' as VertriebsgesellschaftEnum,
        AllfinanzAg: 'AllfinanzAG' as VertriebsgesellschaftEnum
    };
}


